import { createSlice } from '@reduxjs/toolkit';

interface ViewState {
	view: string;
}

const initialState: ViewState = {
	view: 'orders',
};

export const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		setView: (state, data) => {
			state.view = data.payload;
		},
	},
});

export const { setView } = viewSlice.actions;

export default viewSlice.reducer;
