import React, { FC, useEffect, useState } from 'react';
import {
	Input,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { columnsCalls } from './column';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { returnCalls } from '../../service/CallsService';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import {
	setLoading,
	setCalls,
	setTotalSizeCalls,
	setPageCalls,
	setSizePerPageCalls,
	setSort,
	setStart,
	setSearchStartCalls,
	setSearchEndCalls,
	setSearch,
	setOrder,
} from '../../store/reducers/CallsSlice';
import TablePagination from '@mui/material/TablePagination';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Player from '../Player/Player';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';
import './tableCall.css';
import { format } from 'date-fns';
import GetAppIcon from '@mui/icons-material/GetApp';

const TableCalls: FC = () => {
	const dispatch = useAppDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const {
		isLoading,
		calls,
		sort,
		page,
		order,
		skip,
		searchOption,
		totalSize,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
		callType
	} = useAppSelector((state) => state.callsReducer);
	const [statusCol, setStatusCol] = useState<string>('all');
	const [stepCol, setStepCol] = useState<string>('all');

	useEffect(() => {
		(async () => {
			dispatch(setLoading(true));
			const response = await returnCalls(
				page,
				searchOption,
				skip,
				sizePerPage,
				sort,
				order,
				searchStart,
				searchEnd,
				search,
				'order'
			);
			if (response.error) {
				dispatch(setLoading(false));
				dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp: 'Произошла ошибка при получении списка звонков',
					})
				);
			} else {
				dispatch(setLoading(false));
				const { total, data } = response.data.data;
				dispatch(setCalls(data));
				dispatch(setTotalSizeCalls(total));
			}
		})();
	}, [
		page,
		sort,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
		// dispatch,
		profile,
		searchOption,
		skip
	]);

	const handleChangePage = (event: any, newPage: any) => {
		dispatch(setPageCalls(newPage + 1));
		dispatch(setStart(newPage * sizePerPage));
	};

	const handleChangeRowsPerPage = (e: any) => {
		dispatch(setSizePerPageCalls(e.target.value));
		dispatch(setPageCalls(1));
		dispatch(setStart(0));
	};

	const createSortHandler = (e: any, field: string) => {
		console.log(e.target.tagName);
		if (
			e.target.tagName === 'svg' ||
			e.target.tagName === 'SPAN' ||
			e.target.tagName === 'path'
		) {
			const isAsc = sort.sortField === field && order.sortOrder === '1';
			dispatch(setPageCalls(1));
			dispatch(
				setSort({
					sortField: field,
				})
			);
			dispatch(
				setOrder({
					sortOrder: isAsc ? '-1' : '1',
				})
			);
		}
	};

	const changeTime = (time: any) => {
		const transformTime = (time: Date) => {
			return `${time.getFullYear()}-${time.getMonth() + 1 < 10
				? `0${time.getMonth() + 1}`
				: time.getMonth() + 1
				}-${time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()} ${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
				}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
				}`;
		};
		const normalizeTime = (): string => {
			const startTimeNorm = transformTime(time[0]).split(' ');
			const endTimeNorm = transformTime(time[1]).split(' ');
			if (startTimeNorm[1] === '00:00' && endTimeNorm[1] === '00:00') {
				endTimeNorm[1] = '23:59';
			}
			return endTimeNorm.join(' ');
		};
		const endTime = normalizeTime();
		dispatch(setSearchStartCalls(transformTime(time[0])));
		dispatch(setSearchEndCalls(endTime));
	};

	// @ts-ignore
	const customLabel = ({ from, to, count }) => {
		return `${from}-${to} из ${count}`;
	};

	const handleChangeInput = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		// console.log(evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value;
		dispatch(setSearch(obj));
	};

	const handleSelect = (evt: SelectChangeEvent<any>, type: string) => {
		// console.log('событие', evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value === 'all' ? '' : evt.target.value;
		if (type === 'status') {
			setStatusCol(evt.target.value);
			dispatch(setSearch(obj));
		} else if (type === 'step') {
			setStepCol(evt.target.value);
			dispatch(setSearch(obj));
		}
	};

	const setValue = (type: string) => {
		if (type === 'status') {
			return statusCol;
		} else if (type === 'step') {
			return stepCol;
		}
	};

	const createMarkup = (text: string) => {
		return { __html: text };
	};

	const renderMediaCell = (row: any) => {
		if (row?.audioLink) {
			return (<div className="audioCell"><div><a className="getAppIconAudio" href={row?.audioLink} download><GetAppIcon /></a> </div> <div><Player audioLink={row?.audioLink} /></div></div>)
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let timeout: any = 0;
		return (...args: Parameters<T>): ReturnType<T> => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let result: any;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				result = callback(...args);
			}, waitFor);
			return result;
		};
	};

	const debouncedHandle = debounce(handleChangeInput, 1000)

	return (
		<div className="container">
			<p className="label">Журнал звонков</p>
			<div className="wrapper-for_datarange">
				<CustomProvider locale={ruRU}>
					<DateRangePicker
						cleanable={false}
						// ranges={[]}
						onChange={changeTime}
						hoverRange={(date) => [date, date]}
						value={[
							new Date(searchStart.replace(/-/g, '/')),
							new Date(searchEnd.replace(/-/g, '/')),
						]}
						format="yyyy-MM-dd HH:mm"
					/>
				</CustomProvider>
			</div>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer className="tableContainer">
					<Table className="table" stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow className="row_header">
								{columnsCalls.map((column) => (
									<TableCell
										align="center"
										key={column.field}
										padding="normal"
										style={{
											width: column.width,
										}}
										// @ts-ignore
										sortDirection={
											sort.sortField === column.field && order.sortOrder === '1'
												? 'asc'
												: 'desc'
										}
									>
										{column.headerName}
										{column.sort ? (
											<TableSortLabel
												key={column.field}
												active={sort.sortField === column.field}
												// @ts-ignore
												direction={
													sort.sortField === column.field && order.sortOrder === '1'
														? 'asc'
														: 'desc'
												}
												onClick={(e) => createSortHandler(e, column.field)}
											/>
										) : null}
										{column.input ? (
											<Input
												key={`${column.field}_input`}
												className={column.input ? 'tableCell_input' : ''}
												// id="component-helper"
												onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
													debouncedHandle(evt, `${column.field}`)
												}
												aria-describedby="component-helper-text"
											/>
										) : null}
										{column.select ? (
											<Select
												key={`${column.field}_select`}
												className={column.select ? 'tableCell_select' : ''}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={setValue(`${column.field}`)}
												label="Age"
												onChange={(evt: SelectChangeEvent<any>) =>
													handleSelect(evt, `${column.field}`)
												}
											>
												{column.menuItem.map((item) => {
													return (
														<MenuItem key={item.value} value={item.value}>
															{item.label}
														</MenuItem>
													);
												})}
											</Select>
										) : null}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{calls.length > 0 ? (
								calls.map((row: any, index) => {
									return (
										<TableRow
											className={index % 2 ? 'row_background' : ''}
											key={row?._id}
										>
											<TableCell align="center">{format(new Date(row?.date), 'dd/MM/yyyy  kk:mm:ss')}</TableCell>
											<TableCell align="center">{row?.msisdn}</TableCell>
											<TableCell
												align="center"
												dangerouslySetInnerHTML={createMarkup(row?.data)}
											/>
											<TableCell align="center">{row?.status}</TableCell>
											<TableCell>
												{row?.audioLink ? (
													<div>
														{renderMediaCell(row)}
													</div>
												) : null}
											</TableCell>
											<TableCell align="center">{row?.step}</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow />
							)}
						</TableBody>
					</Table>
					{calls.length > 0 ? null : (
						<p className="label-no_records">Нет записей</p>
					)}
				</TableContainer>
			</Paper>
			{isLoading ? <LinearProgress /> : null}
			<div className="wrapper-footer row_background">
				<div className="wrapper-buttons">
				</div>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100, 500]}
					component="div"
					count={totalSize}
					rowsPerPage={sizePerPage}
					page={page - 1}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="На странице:"
					labelDisplayedRows={customLabel}
				/>
			</div>
		</div>
	);
};

export default TableCalls;
