export default function ArrowBottomIcon(): JSX.Element {
	return (
		<svg
			width="10"
			height="6"
			viewBox="0 0 10 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.1802 5.09243L9.5 0.773646L8.9505 0.223373L4.82082 4.33675L0.714063 0.200195L0.141631 0.772628L4.46041 5.09243L4.82082 5.45283L5.1802 5.09243Z"
				fill="white"
			/>
		</svg>
	);
}
