import axios from 'axios';
import resolve from './resolve';
import { IProfile } from '../models/IProfile';
import { API_PREFIX } from '../utils/const';

interface testSipParams {
	lines_shop: string;
	phone_shop: string;
	phone_pass_shop: string;
	server_sip_shop: string;
}
interface testSipResp {
	statusSip: string;
	timeUpdateSip: string;
}

export async function testSip({
	lines_shop,
	phone_shop,
	phone_pass_shop,
	server_sip_shop,
}: testSipParams): Promise<any> {
	return await resolve(
		/* test_sip */
		axios.patch<testSipResp>(`${API_PREFIX}/users`, {
			withCredentials: true,
			lines_shop: lines_shop,
			phone_shop: phone_shop,
			phone_pass_shop: phone_pass_shop,
			server_sip_shop: server_sip_shop,
		})
	);
}

export async function changeSip({
	lines_shop,
	phone_shop,
	phone_pass_shop,
	server_sip_shop,
}: testSipParams): Promise<any> {
	return await resolve(
		/* change_sip */
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			lines_shop: lines_shop,
			phone_shop: phone_shop,
			phone_pass_shop: phone_pass_shop,
			server_sip_shop: server_sip_shop,
		})
	);
}

export async function activateLicence(key: string): Promise<any> {
	return await resolve(
		/* activate_licence */
		axios.get(`${API_PREFIX}/users/key?key=${key}`, {
			withCredentials: true,
		})
	);
}

export async function updateProfile(
	name: string,
	play_list_items: string,
	play_send_email: string,
	recording_shop: boolean,
	play_like_text: string,
	url: string,
	first_text: string,
	second_text: string,
	third_text: string,
	fourth_text: string,
	confirm_text: string,
	like_text: string,
	last_text: string,
	retry_call_shop: string,
	token_access: string,
	bitrix_integration_shop: boolean,
	tasks_text: string,
	negative_feedback: string,
	positive_feedback: string,
	voice: string
): Promise<any> {
	return await resolve(
		axios.patch<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			name: name,
			play_list_items: play_list_items,
			play_send_email: play_send_email,
			recording_shop: recording_shop,
			play_like_text: play_like_text,
			url: url,
			first_text: first_text,
			second_text: second_text,
			third_text: third_text,
			fourth_text: fourth_text,
			confirm_text: confirm_text,
			like_text: like_text,
			last_text: last_text,
			retry_call_shop: retry_call_shop,
			token_access: token_access,
			bitrix_integration_shop: bitrix_integration_shop,
			tasks_text: tasks_text,
			negative_feedback: negative_feedback,
			positive_feedback: positive_feedback,
			voice: voice,
		})
	);
}

export async function setTestTask(
	number: string,
	orders: string,
	email: string,
	phone: string,
	voice: string,
	token: string
): Promise<any> {
	return await resolve(
		/* tasks */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			number: number,
			orders: orders,
			email: email,
			phone: phone,
			voice: voice,
			token: token,
		})
	);
}

export async function changePassword(password: string): Promise<any> {
	return await resolve(
		/* change_password */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			password: password,
		})
	);
}

export async function changeDisplayName(display_name: string): Promise<any> {
	return await resolve(
		/* change_name */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			display_name: display_name,
		})
	);
}

export async function changeApiKey(apiKey: string): Promise<any> {
	return await resolve(
		/* change_name */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			apiKey: apiKey,
		})
	);
}

export async function setWarningBalance(warning: boolean): Promise<any> {
	return await resolve(
		/* message_balance */
		axios.patch(`${API_PREFIX}/users`, {
			withCredentials: true,
			warning: warning,
		})
	);
}

export async function getProfile(): Promise<any> {
	return await resolve(
		axios.get(`${API_PREFIX}/users`, {
			withCredentials: true,
		})
	);
}
