import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfile } from '../models/IProfile';
import axios from 'axios';
import { API_PREFIX } from '../utils/const';

export const fetchProfile = createAsyncThunk('users', async (_, thunkAPI) => {
	try {
		const response = await axios.get<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': '0'
			}
		});
		return response.data;
	} catch (e) {
		return thunkAPI.rejectWithValue(`${e}`);
	}
});

export const fetchAuth = createAsyncThunk('users/auth', async (_, thunkAPI) => {
	try {
		const response = await axios.get<IProfile>(`${API_PREFIX}/users`, {
			withCredentials: true,
		});
		return response.data;
	} catch (e) {
		return thunkAPI.rejectWithValue(`${e}`);
	}
});
