export const columnsVideo = [
	{
		headerName: 'Дата',
		field: 'date',
		width: 250,
		sort: true,
	},
	{
		headerName: 'Номер заказа',
		field: 'order_number',
		input: true,
		width: 250,
	},
	{
		headerName: 'Файл',
		field: 'media',
		width: 250,
	},
];
