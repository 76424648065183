import React, { FC } from 'react';
import Header from '../Header/Header';
import TableCalls from '../TableCalls/TableCall';
import MessageBalance from '../MessageBalance/MessageBalance';
import TableStats from '../TableStats/TableStats';
import { useAppSelector } from '../../hooks/redux';
import TableOrders from '../TableOrders/TableOrders';
import TableVideo from '../TableVideo/TableVideo';
import TableEvaluations from '../TableEvaluations/TableEvaluations';

const Main: FC = () => {
	const { view } = useAppSelector((state) => state.viewReducer);
	return (
		<>
			<Header />
			{view === 'calls' ? <TableCalls /> : null}
			{view === 'stats' ? <TableStats /> : null}
			{view === 'orders' ? <TableOrders /> : null}
			{view === 'video' ? <TableVideo /> : null}
			{view === 'evaluations' ? <TableEvaluations /> : null}
			<MessageBalance />
		</>
	);
};

export default Main;
