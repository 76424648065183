import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../hooks/redux';
import styles from './testForm.module.css';

interface TestFormProps {
	updateTestData: (type: string, value: string) => void;
}
const TestForm: FC<TestFormProps> = ({ updateTestData }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const voiceValue: string =
		typeof (profile?.voice) === 'string' ? profile?.voice : 'oksana';
	// const [voice, setVoice] = useState<string>(profile!.voice);
	const [voice, setVoice] = useState<string>(voiceValue);
	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'number') {
			// setNumber(evt.target.value);
			updateTestData('number', evt.target.value);
		} else if (type === 'orders') {
			// setOrders(evt.target.value);
			updateTestData('orders', evt.target.value);
		} else if (type === 'email') {
			// setTestEmail(evt.target.value);
			updateTestData('testEmail', evt.target.value);
		} else if (type === 'phone') {
			// setPhone(evt.target.value);
			updateTestData('phone', evt.target.value);
		}
	};
	const handleSelect = (evt: SelectChangeEvent<any>) => {
		// console.log('событие')
		setVoice(evt.target.value);
		updateTestData('voice', evt.target.value);
	};


	return (
		<div className={styles.container}>
			<div className={styles.wrapper__div}>
				<DialogContent className={styles.dialog_content}>
					<DialogContentText className={styles.label_for_context_text}>
						Данные для тестирования сценария
					</DialogContentText>
					<TextField
						id="number_test"
						fullWidth
						variant="standard"
						label="Номер заказа"
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(evt, 'number')
						}
					/>
					<TextField
						id="orders_test"
						fullWidth
						variant="standard"
						label="Состав заказа"
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(evt, 'orders')
						}
					/>
					<TextField
						id="email_test"
						fullWidth
						variant="standard"
						label="E-mail покупателя"
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(evt, 'email')
						}
					/>
					<TextField
						id="phone_test"
						fullWidth
						variant="standard"
						label="Номер телефона покупателя"
						onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
							handleChange(evt, 'phone')
						}
					/>
					<InputLabel
						className={styles.voice_input}
						id="demo-simple-select-label"
					>
						Выбор голоса
					</InputLabel>
					<Select
						className={`${styles.voice_select} change-input-outlined`}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						defaultValue={voice}
						onChange={handleSelect}
					>
						<MenuItem value={'jane'}>jane</MenuItem>
						<MenuItem value={'oksana'}>oksana</MenuItem>
						<MenuItem value={'omazh'}>omazh</MenuItem>
						<MenuItem value={'zahar'}>zahar</MenuItem>
						<MenuItem value={'ermil'}>ermil</MenuItem>
					</Select>
				</DialogContent>
			</div>
		</div>
	);
};

export default TestForm;
