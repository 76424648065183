import React, { FC, ReactNode } from 'react';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import './CustomButton.css';

type CustomButtonProps = {
	onClick: () => void,
	text: string,
	disabled: boolean,
	disabledActivateButton: boolean,
	className: ReactNode,
};
const CustomButton: FC<CustomButtonProps> = ({ text, disabled, onClick,disabledActivateButton }) => {
	return (
		<div className={
			disabledActivateButton
				? 'activate_balance activate_balance_disabled'
				: 'activate_balance'
		} onClick={onClick}>
			{disabled ? (
				<LoadingButton
					size="large"
					loading={disabled}
					loadingPosition="center"
					loadingIndicator={
						<CircularProgress size={22} thickness={3} disableShrink={true} />
					}
					disabled={disabled}
				/>
			) : (
				text
			)}
		</div>
	);
};

export default CustomButton;
