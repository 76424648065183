import { createSlice } from '@reduxjs/toolkit';

interface InfoState {
	isErrorApp: boolean;
	errorApp: string;
	isInfoApp: boolean;
	infoApp: string;
}

const initialState: InfoState = {
	isErrorApp: false,
	errorApp: ``,
	isInfoApp: false,
	infoApp: '',
};

export const infoSlice = createSlice({
	name: 'info',
	initialState,
	reducers: {
		setErrorApp: (state, data) => {
			state.isErrorApp = data.payload.isErrorApp;
			state.errorApp = data.payload.errorApp;
		},
		setInfoApp: (state, data) => {
			state.isInfoApp = data.payload.isInfoApp;
			state.infoApp = data.payload.infoApp;
		},
	},
});

export const { setErrorApp, setInfoApp } = infoSlice.actions;

export default infoSlice.reducer;
