export const URL_SERVER = window._env_ ? window._env_.URL_SERVER : 'http://localhost:3000';
export const URL_AUTH = window._env_ ? window._env_.URL_AUTH : 'https://id.kloud.one/authorize/?redirect_uri=http://localhost:3000/api/v1/auth/callback&client_id=0df5cb99771b406ba9fe91f3b3ee8041';
export const BITRIX_INTEGRETION = true
export const TYPE_SERVICE = window._env_ ? window._env_.STATE_SERVICE : 'shop';
export const LINK_BUY = window._env_ ? window._env_.LINK_BUY : "https://kloud.one/shop/#buy";
export const API_PREFIX = window._env_ ? window._env_.API_PREFIX : "api/v1";
export const TASKS_TEXT =
	'Здравствуйте Вас приветствует интернет-магазин <@name@>!  Хотим оценить работу интернет магазина по заказу №<@number@>';
export const POSITIVE_FEEDBACK =
	'Что вам понравилось, оставьте отзыв';
export const NEGATIVE_FEEDBACK =
	'Что вам не понравилось, оставьте отзыв'