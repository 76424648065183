export const columnsEvaluations = [
	{
		headerName: 'Дата',
		field: 'date',
		width: 350,
		sort: true,
	},
	{
		headerName: 'Телефон',
		field: 'msisdn',
		input: true,
		width: 250,
	},
	{
		headerName: 'Данные',
		field: 'data',
		input: true,
		width: 250,
	},
	{
		headerName: 'Статус',
		field: 'status',
		width: 250,
		select: true,
		sort: false,
		selectValue: 'all',
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'failed', label: 'Не проведено' },
			{ value: 'rated', label: 'Оценка получена' },
			{ value: 'notRated', label: 'Оценка не получена' },
		],
	},
	{
		headerName: 'Оценка',
		field: 'rating',
		width: 250,
		select: true,
		selectValue: 'all',
		sort: false,
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: '1', label: '1' },
			{ value: '2', label: '2' },
			{ value: '3', label: '3' },
			{ value: '4', label: '4' },
			{ value: '5', label: '5' },
		],
	},
	{
		headerName: 'Файл',
		field: 'media',
		width: 250,
	},
];
