import resolve from './resolve';
import axios from 'axios';
import { URL_SERVER } from '../utils/const';

interface IStats {
	service: string;
	date: string;
	operations: string;
	clientID: string;
	sessionId: string | null;
	value: number;
	balance: number | null;
}

interface returnStatsI {
	total: number;
	data: IStats[];
	success: boolean;
}
interface searchI {
	[key: string]: string;
}

export async function returnStats(
	page: number,
	searchOption: string,
	start: number,
	sizePerPage: number,
	sort: {
		sortOrder: any;
		sortField: any;
	},
	clientID: string,
	searchStart: string,
	searchEnd: string,
	search: searchI
): Promise<any> {
	// console.log(search,'search')
	const reqSearch = () => {
		const normalizedGdate = (date: string): string => {
			return date.replace(/-/g, '.');
		};
		const searchObj: searchI = {
			service: 'shop',
			clientID: clientID,
			date: `${normalizedGdate(searchStart)}|${normalizedGdate(searchEnd)}`,
		};

		Object.keys(search).forEach((key) => {
			if (search[key].length > 0) {
				searchObj[key] = search[key];
			}
		});
		return JSON.stringify(searchObj);
	};
	return await resolve(
		axios.get<returnStatsI>(`${URL_SERVER}/users/stats`, {
			withCredentials: true,
			params: {
				page: page,
				search: reqSearch(),
				searchOption: searchOption,
				start: start,
				limit: sizePerPage,
				sort: String(
					JSON.stringify([
						{
							property: sort.sortField,
							direction: sort.sortOrder.toUpperCase(),
						},
					])
				),
			},
		})
	);
}
