import React, { FC, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Input,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { columnsOrders } from './column';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import {
	setLoading,
	setOrders,
	setTotalOrders,
	setPageOrders,
	setSizePerPageOrders,
	setSort,
	setStart,
	setSearchStartOrders,
	setSearchEndOrders,
	setSearch,
	setOrder,
} from '../../store/reducers/OrdersSlice';
import TablePagination from '@mui/material/TablePagination';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';
import './tableOrders.css';
import { changeOrders, returnOrders } from '../../service/OrdersService';
import Player from '../Player/Player';
import { Delete } from '@mui/icons-material';
import PlayerVideo from '../PlayerVideo/PlayerVideo';
import CloseIcon from '@mui/icons-material/Close';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ElemInPopover, PopoverComponent } from '../PopoverComponent/PopoverComponent';
import format from 'date-fns/format';


const TableOrders: FC = () => {
	const dispatch = useAppDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const {
		isLoading,
		orders,
		sort,
		order,
		page,
		skip,
		searchOption,
		totalSize,
		sizePerPage,
		searchStart,
		searchEnd,
		search
	} = useAppSelector((state) => state.ordersReducer);
	const [statusCol, setStatusCol] = useState<string>('all');
	const [stepCol, setStepCol] = useState<string>('all');
	const [evalCol, setEvalCol] = useState<string>('all');
	const [selected, setSelected] = useState<string[]>([]);
	const [selectedAll, setSelectedAll] = useState<boolean>(false);
	const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [popoverShowStatus, setPopoverShowStatus] = useState<(ElemInPopover | null)[]>([]);


	useEffect(() => {
		(async () => {
			dispatch(setLoading(true));
			const response = await returnOrders(
				page,
				searchOption,
				skip,
				sizePerPage,
				sort,
				order,
				searchStart,
				searchEnd,
				search
			);
			if (response.error) {
				dispatch(setLoading(false));
				dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp: 'Произошла ошибка при получении списка заказов',
					})
				);
			} else {
				dispatch(setLoading(false));
				const { total, data } = response.data.data;
				dispatch(setOrders(data));
				dispatch(setTotalOrders(total));
			}
		})();
	}, [
		page,
		sort,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
		// dispatch,
		profile,
		searchOption,
		skip
	]);

	const loadOrders = async () => {
		dispatch(setLoading(true));
		const response = await returnOrders(
			page,
			searchOption,
			skip,
			sizePerPage,
			sort,
			order,
			searchStart,
			searchEnd,
			search
		);
		if (response.error) {
			dispatch(setLoading(false));
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при получении списка заказов',
				})
			);
		} else {
			dispatch(setLoading(false));
			const { total, data } = response.data.data;
			dispatch(setOrders(data));
			dispatch(setTotalOrders(total));
		}
	};

	const upPopover = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setPopoverVisible(true)
		setAnchorEl(event.currentTarget)
		const selectedOrders: any[] = selected.map(selectedId => orders.find(item => item['_id'] === selectedId));
		const hasRequiresConfirmation = selectedOrders.some(e => e?.order_status === 'Требует подтверждения');
		const hasRequiresPackaging = selectedOrders.some(e => e?.package_status === 'Требует упаковки');
		const hasRequiresEvaluation = selectedOrders.some(e => e?.evaluation_status === 'Требует оценки');

		if (!hasRequiresConfirmation && !hasRequiresPackaging && !hasRequiresEvaluation) {
			setPopoverShowStatus([]);
			setPopoverVisible(false);
			return;
		}

		setPopoverShowStatus([
			hasRequiresConfirmation ? ElemInPopover.orderStatus : null,
			hasRequiresPackaging ? ElemInPopover.packageStatus : null,
			hasRequiresEvaluation ? ElemInPopover.evaluationStatus : null
		]);
	};

	const handleChangeStatus = async (value: "order_status" | "package_status" | "evaluation_status") => {
		dispatch(setLoading(true));
		const promises: void[] = [];
		for (const item of selected) {
			const response = await changeOrders(item, value);
			promises.push(response);
		}
		Promise.all(promises).then(async () => {
			dispatch(setLoading(false));
			dispatch(setInfoApp({ isInfoApp: true, infoApp: 'Статусы изменились' }));
			setSelectedAll(false);
			setSelected([]);
			await loadOrders();
			setPopoverVisible(false)
		});
	};

	const handleChangeStatusOne = async (id: string, value: "order_status" | "package_status" | "evaluation_status") => {
		dispatch(setLoading(true));
		await changeOrders(id, value);
		dispatch(setLoading(false));
		dispatch(setInfoApp({ isInfoApp: true, infoApp: 'Статусы изменились' }));
		await loadOrders();
	};

	useEffect(() => {
		(async () => {
			await loadOrders();
		})();
	}, [page, sort, sizePerPage, search, dispatch]);

	useEffect(() => {
		selected.length === 0 ? setSelectedAll(false) : setSelectedAll(true);
	}, [selected]);

	const onSelectAllClick = (event: any) => {
		if (event.target.checked && orders.length > 0) {
			setSelectedAll(true);
			setSelected(
				orders.map((item) => `${item['_id']}`)
			);
			return;
		}
		setSelected([]);
		setSelectedAll(false);
	};

	const handleChangePage = (event: any, newPage: any) => {
		dispatch(setPageOrders(newPage + 1));
		dispatch(setStart(newPage * sizePerPage));
	};

	const handleChangeRowsPerPage = (e: any) => {
		dispatch(setSizePerPageOrders(e.target.value));
		dispatch(setPageOrders(1));
		dispatch(setStart(0));
	};

	const createSortHandler = (e: any, field: string) => {
		if (
			e.target.tagName === 'svg' ||
			e.target.tagName === 'SPAN' ||
			e.target.tagName === 'path'
		) {
			const isAsc = sort.sortField === field && order.sortOrder === '1';
			dispatch(setPageOrders(1));
			dispatch(
				setSort({
					sortField: field,
				})
			);
			dispatch(
				setOrder({
					sortOrder: isAsc ? '-1' : '1',
				})
			);
		}
	};

	// const changeDateInTable = (date: string) => {
	// 	date = date.replace(/\./g, '-');
	// 	date = date.replace(/ /, 'T');
	// 	date = date + '.000';
	// 	const newDate: Date = new Date(date);
	// 	let tranformDate: string;
	// 	if (profile && profile?.gmt_shop)
	// 		newDate.setHours(
	// 			newDate.getHours() + parseInt(profile?.gmt_shop) - 3
	// 		);
	// 	try {
	// 		tranformDate = newDate.toLocaleString();
	// 	} catch (err) {
	// 		tranformDate = newDate.toISOString();
	// 	}
	// 	return tranformDate;
	// };

	const changeTime = (time: any) => {
		const transformTime = (time: Date) => {
			return `${time.getFullYear()}-${time.getMonth() + 1 < 10
				? `0${time.getMonth() + 1}`
				: time.getMonth() + 1
				}-${time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()} ${time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
				}:${time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
				}`;
		};
		const normalizeTime = (): string => {
			const startTimeNorm = transformTime(time[0]).split(' ');
			const endTimeNorm = transformTime(time[1]).split(' ');
			if (startTimeNorm[1] === '00:00' && endTimeNorm[1] === '00:00') {
				endTimeNorm[1] = '23:59';
			}
			return endTimeNorm.join(' ');
		};
		const endTime = normalizeTime();
		dispatch(setSearchStartOrders(transformTime(time[0])));
		dispatch(setSearchEndOrders(endTime));
	};

	// @ts-ignore
	const customLabel = ({ from, to, count }) => {
		return `${from}-${to} из ${count}`;
	};

	const handleChangeInput = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		// console.log(evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value;
		dispatch(setSearch(obj));
	};

	const handleSelect = (evt: SelectChangeEvent<any>, type: string) => {
		// console.log('событие', evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value === 'all' ? '' : evt.target.value;
		if (type === 'order_status') {
			setStatusCol(evt.target.value);
			dispatch(setSearch(obj));
		} else if (type === 'evaluation_status') {
			setEvalCol(evt.target.value);
			dispatch(setSearch(obj));
		} else if (type === 'package_status') {
			setStepCol(evt.target.value);
			dispatch(setSearch(obj));
		}
	};

	const setValue = (type: string) => {
		if (type === 'order_status') {
			return statusCol;
		} else if (type === 'package_status') {
			return stepCol;
		} else if (type === 'evaluation_status') {
			return evalCol;
		}
	};

	const handleClick = (event: any, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};
	const checkSelected = (id: string) => selected.indexOf(id) !== -1;

	const renderAudioCell = (row: any) => {
		if (row?.order_status === "none") {
			return
		}
		if (row?.order_status === "Ошибка" || row?.order_status === "Остановлен") {
			return (<div>{row?.order_status}</div>)
		}
		if (row?.order_status === "Отменен" && row?.audioLink ) {
			return (
				<div className="audioCell">
					{row?.order_status}
					<div>
						<a className="getAppIconAudio" href={row?.audioLink} download>
							<GetAppIcon />
						</a>
					</div>
					<div>
						<Player audioLink={row?.audioLink} />
					</div>
				</div>
			)
		}
		if (row?.order_status === "Требует подтверждения") {
			return (
				<div className="audioCell">
					{row?.order_status}
					<div className="getAppIconAudio">
						<CloseIcon onClick={() => handleChangeStatusOne(row?._id, 'order_status')} />
					</div>
				</div>
			)
		}
		if (row?.order_status === "Подтвержден" && row?.audioLink) {
			return (
				<div className="audioCell">
					{row?.order_status}
					<div>
						<a className="getAppIconAudio" href={row?.audioLink} download >
							<GetAppIcon />
						</a>
					</div>
					<div>
						<Player audioLink={row?.audioLink} />
					</div>
				</div>
			)
		}
	}

	const renderVideoCell = (row: any) => {
		if (row?.package_status === "none") {
			return
		}
		if (row?.package_status === "Остановлен") {
			return (<div>{row?.package_status}</div>)
		}
		if (row?.package_status === "Требует упаковки") {
			return (
				<div className="audioCell">
					{row?.package_status}
					<div className="getAppIconVideo">
						<CloseIcon onClick={() => handleChangeStatusOne(row?._id, 'package_status')} />
					</div>
				</div>
			)
		}
		if (row?.package_status === "Видео записано" && row?.videoLink) {
			return (<div className="audioCell">
				{row?.package_status}
				<div>
					<a className="getAppIconVideo" href={`${row?.videoLink}&download=true`} download>
						<GetAppIcon />
					</a>
				</div>
				<div>
					<PlayerVideo videoLink={row?.videoLink} />
				</div>
			</div>
			)
		}
	}

	const renderEvalCell = (row: any) => {
		if (row?.evaluation_status === "none") {
			return
		}
		if (row?.evaluation_status === "Ошибка" ||
			(row?.evaluation_status === "Не проведено") ||
			(row?.evaluation_status === "Остановлен") ||
			row?.evaluation_status === "Оценка не получена") {
			return (<div>{row?.evaluation_status}</div>)
		}
		if (row?.evaluation_status === "Требует оценки") {
			return (<div className="audioCell">
				{row?.evaluation_status}
				<div className="getAppIconVideo">
					<CloseIcon onClick={() => handleChangeStatusOne(row?._id, 'evaluation_status')} />
				</div>
			</div>
			)
		}
		if (row?.evaluation_status === "Оценка получена" && row?.evalLink) {
			return (<div className="audioCell">
				{row?.evaluation_status}
				<div>
					<a className="getAppIconVideo" href={row?.evalLink} download>
						<GetAppIcon />
					</a>
				</div>
				<div>
					<Player audioLink={row?.evalLink} />
				</div>
			</div>
			)
		}
	}

	const renderOrders = () => {
		const localOrders = orders.filter((row: any) => {
			if (statusCol === "all" && stepCol === "all") {
				return true;
			}

			if (statusCol === "all") {
				return row.package_status === stepCol;
			}

			if (stepCol === "all") {
				return row.order_status === statusCol;
			}

			if (evalCol === "all") {
				return row.evaluation_status === evalCol;
			}

			return row.package_status === stepCol && row.order_status === statusCol && row.evaluation_status === evalCol;
		});
		return localOrders.map((row: any, index) => {
			const isSelected = checkSelected(
				`${row?._id}`
			);
			return (
				<TableRow
					className={index % 2 ? 'row_background' : ''}
					key={`${row?._id}`}
					hover
					// onClick={(event) =>
					// 	handleClick(
					// 		event,
					// 		`${row?._id}`
					// 	)
					// }
					// onClick={()=>console.log(`click ${row['_id']}`)}
					role="checkbox"
					aria-checked={isSelected}
					tabIndex={-1}
					// key={n.id}
					selected={isSelected}
				>
					<TableCell className="selectCheckbox" padding="checkbox">
						<Checkbox
							onClick={(event) =>
								handleClick(
									event,
									`${row?._id}`
								)
							}
							className="selectCheckbox"
							checked={isSelected}
						/>
					</TableCell>
					<TableCell align="center">{row?.order_number}</TableCell>
					<TableCell align="center">{format(new Date(row?.date), 'dd/MM/yyyy  kk:mm:ss')}</TableCell>
					<TableCell align="center">{row?.order_status ? (
						<div>
							{renderAudioCell(row)}
						</div>
					) : null}
					</TableCell>
					<TableCell align="center">{row?.package_status ? (
						<div>
							{renderVideoCell(row)}
						</div>
					) : null}
					</TableCell>
					<TableCell align="center">{row?.evaluation_status ? (
						<div>
							{renderEvalCell(row)}
						</div>
					) : null}
					</TableCell>
				</TableRow>
			);
		});
	}

	const selectedOrdersId: any[] = selected.map(selectedId => orders.find(item => item['_id'] === selectedId));
	const hasRequiresConfirmationId = selectedOrdersId.some(e => e?.order_status === 'Требует подтверждения');
	const hasRequiresPackagingId = selectedOrdersId.some(e => e?.package_status === 'Требует упаковки');
	const hasRequiresEvaluationId = selectedOrdersId.some(e => e?.evaluation_status === 'Требует оценки');

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let timeout: any = 0;
		return (...args: Parameters<T>): ReturnType<T> => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let result: any;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				result = callback(...args);
			}, waitFor);
			return result;
		};
	};

	const debouncedHandle = debounce(handleChangeInput, 1000)

	// const handleDownload = (url: any, filename: string) => {
	// 	axios.get(url, {
	// 		headers: {
	// 			'Content-disposition': 'attachment',
	// 			'Content-Type': 'application / json'
	// 		},
	// 		responseType: 'blob',
	// 	})
	// 		.then((res) => {
	// 			fileDownload(res.data, filename)
	// 		})
	// }

	return (
		<div className="container">
			<p className="label">Заказы</p>
			<div className="wrapper-for_datarange">
				<CustomProvider locale={ruRU}>
					<DateRangePicker
						cleanable={false}
						// ranges={[]}
						onChange={changeTime}
						hoverRange={(date) => [date, date]}
						value={[
							new Date(searchStart.replace(/-/g, '/')),
							new Date(searchEnd.replace(/-/g, '/')),
						]}
						format="yyyy-MM-dd HH:mm"
					/>
				</CustomProvider>
			</div>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer className="tableContainer">
					<Table className="table" stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow className="row_header">
								<TableCell
									style={{
										width: 50,
									}}
								>
									<Checkbox
										// indeterminate={numSelected > 0 && numSelected < rowCount}
										checked={selectedAll}
										onChange={onSelectAllClick}
									/>
								</TableCell>
								{columnsOrders.map((column) => (
									<TableCell
										align="center"
										key={column.field}
										padding="normal"
										style={{
											width: column.width,
										}}
										// @ts-ignore
										sortDirection={
											sort.sortField === column.field && order.sortOrder === '1'
												? 'asc'
												: 'desc'
										}
									>
										{column.headerName}
										{column.sort ? (
											<TableSortLabel
												key={column.field}
												active={sort.sortField === column.field}
												// @ts-ignore
												direction={
													sort.sortField === column.field && order.sortOrder === '1'
														? 'asc'
														: 'desc'
												}
												onClick={(e) => createSortHandler(e, column.field)}
											/>
										) : null}
										{column.input ? (
											<Input
												key={`${column.field}_input`}
												className={column.input ? 'tableCell_input' : ''}
												// id="component-helper"
												onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
													debouncedHandle(evt, `${column.field}`)
												}
												aria-describedby="component-helper-text"
											/>
										) : null}
										{column.select ? (
											<Select
												key={`${column.field}_select`}
												className={column.select ? 'tableCell_select' : ''}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={setValue(`${column.field}`)}
												label="Age"
												onChange={(evt: SelectChangeEvent<any>) =>
													handleSelect(evt, `${column.field}`)
												}
											>
												{column.menuItem.map((item) => {
													return (
														<MenuItem key={item.value} value={item.value}>
															{item.label}
														</MenuItem>
													);
												})}
											</Select>
										) : null}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{orders.length > 0 ? (
								renderOrders()
							) : (
								<TableRow />
							)}
						</TableBody>
					</Table>
					{orders.length > 0 ? null : (
						<p className="label-no_records">Нет записей</p>
					)}
				</TableContainer>
			</Paper>
			{isLoading ? <LinearProgress /> : null}
			<div className="wrapper-footer row_background">
				<div className="wrapper-buttons">
					{selected.length > 0 &&
						(hasRequiresPackagingId ||
							hasRequiresConfirmationId ||
							hasRequiresEvaluationId) ? (
						<>
							<Button
								disableElevation
								className="delete-button"
								onClick={upPopover}
								startIcon={<Delete />}
								component="span"
							>
								{selected.length > 0 ? "Отменить" : null}
							</Button>
							<PopoverComponent
								open={popoverVisible}
								anchorEl={anchorEl}
								elemInPopover={popoverShowStatus}
								onClose={() => setPopoverVisible(false)}
								onClick={handleChangeStatus} />
						</>
					) : null}
				</div>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100, 500]}
					component="div"
					count={totalSize || 0}
					rowsPerPage={sizePerPage}
					page={page - 1}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="На странице:"
					labelDisplayedRows={customLabel}
				/>
			</div>
		</div>
	);
};

export default TableOrders;
