import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from '../../models/IProfile';
import { fetchAuth, fetchProfile } from '../../service/ProfileServiceThunk';

interface ProfileState {
	profile: IProfile | null;
	isAuthenticated: boolean;
	isLoading: boolean;
	isError: boolean;
	error: string;
	token: string;
}

const initialState: ProfileState = {
	profile: null,
	isLoading: false,
	isAuthenticated: false,
	isError: false,
	error: ``,
	token: '',
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setError: (state, data) => {
			state.isError = data.payload;
		},
		setProfile: (state, data) => {
			state.profile = { ...state, ...data.payload };
		},
		setLoading: (state, data) => {
			state.isLoading = data.payload;
		},
		setToken: (state, data) => {
			state.token = data.payload;
			state.isAuthenticated = true;
		},
	},
	extraReducers: {
		[fetchProfile.fulfilled.type]: (state, action: PayloadAction<IProfile>) => {
			state.profile = { ...state.profile, ...action.payload };
			console.log(state, action, 'fetchProfile fulfilled');
		},
		[fetchProfile.rejected.type]: (state, action: PayloadAction<IProfile>) => {
			state.profile = null;
			console.log(state, action, 'fetchProfile rejected');
		},
		[fetchAuth.fulfilled.type]: (state, action: PayloadAction<IProfile>) => {
			// @ts-ignore
			state.profile = { ...state.profile, ...action.payload };
			state.isLoading = false;
			state.isAuthenticated = true;
			state.error = '';
			console.log(state, action, 'fetchAuth');
		},
		[fetchAuth.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchAuth.rejected.type]: (state, action: PayloadAction<string>) => {
			state.isLoading = false;
			state.error = action.payload;
			state.isError = true;
			console.log(state, action, 'fetchRejected');
		},
	},
});
export const { setError, setProfile, setLoading, setToken } =
	profileSlice.actions;
export default profileSlice.reducer;
