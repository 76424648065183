export default function Wallet():JSX.Element {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.94985 2.84668H1.34985V3.44668V15.1929V15.7929H1.94985H14.4888H15.0888V15.1929V12.8564H15.7571C16.1989 12.8564 16.5571 12.4982 16.5571 12.0564V7.5621C16.5571 7.12028 16.1989 6.7621 15.7571 6.7621H15.0888V3.44668V2.84668H14.4888H12.5311H3.23199H1.94985ZM10.6157 12.8564H13.8888V14.5929H2.54985V4.04668H3.23199H12.5311H13.8888V6.7621H10.6157C9.17976 6.7621 8.0157 7.92616 8.0157 9.3621V10.2564C8.0157 11.6923 9.17975 12.8564 10.6157 12.8564ZM9.2157 9.3621C9.2157 8.5889 9.8425 7.9621 10.6157 7.9621H15.3571V11.6564H14.4888H10.6157C9.8425 11.6564 9.2157 11.0296 9.2157 10.2564V9.3621ZM10.5733 10.7075C11.0238 10.7075 11.389 10.3423 11.389 9.89179C11.389 9.44129 11.0238 9.07608 10.5733 9.07608C10.1227 9.07608 9.75754 9.44129 9.75754 9.89179C9.75754 10.3423 10.1227 10.7075 10.5733 10.7075Z"
				fill="white"
			/>
		</svg>
	);
}
