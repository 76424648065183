export default function Counter(): JSX.Element {
	return (
		<svg
			width="12"
			height="16"
			viewBox="0 0 12 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.96333 11.2306C8.59459 11.2306 10.7276 9.09752 10.7276 6.46626C10.7276 3.83501 8.59459 1.70195 5.96333 1.70195C3.33208 1.70195 1.19902 3.83501 1.19902 6.46626C1.19902 9.09752 3.33208 11.2306 5.96333 11.2306ZM5.96333 12.4306C7.0804 12.4306 8.12574 12.1235 9.01954 11.5891L8.08701 14.3867H3.83965L2.90712 11.5891C3.80092 12.1235 4.84626 12.4306 5.96333 12.4306ZM11.9276 6.46626C11.9276 7.809 11.4839 9.04811 10.7351 10.045L10.7928 10.0642L9.08867 15.1764L8.95192 15.5867H8.51946H3.4072H2.97474L2.83799 15.1764L1.1339 10.0642L1.19154 10.045C0.442737 9.04811 -0.000976562 7.809 -0.000976562 6.46626C-0.000976562 3.17226 2.66933 0.501953 5.96333 0.501953C9.25733 0.501953 11.9276 3.17226 11.9276 6.46626ZM5.19898 5.05034C6.26839 3.35131 6.42723 3.10538 6.46958 3.083C6.53249 3.04976 6.59295 3.04978 6.65819 3.08306C6.71451 3.1118 6.76439 3.18929 6.76439 3.24807C6.76439 3.27202 6.23876 5.38475 6.17008 5.63687L6.16192 5.66684H6.98314C7.77991 5.66684 7.80593 5.66764 7.85693 5.69365C7.94904 5.74065 7.98803 5.85941 7.94125 5.95054C7.92922 5.97398 7.37708 6.855 6.71429 7.90836C5.62185 9.64453 5.50441 9.82599 5.45803 9.84966C5.39283 9.88292 5.33848 9.88271 5.27228 9.84894C5.21582 9.82013 5.16607 9.74268 5.16607 9.68358C5.16607 9.65891 5.71809 7.4346 5.76047 7.28847C5.76687 7.26645 5.72186 7.26516 4.94667 7.26516C4.15056 7.26516 4.12454 7.26436 4.07354 7.23835C4.00504 7.2034 3.96185 7.12287 3.97059 7.04638C3.97559 7.00265 4.21411 6.61508 5.19898 5.05034Z"
				fill="#333333"
			/>
		</svg>
	);
}
