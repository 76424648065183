export default function Settings(): JSX.Element {
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.2707 1.49001L9.97933 1.32812L9.68794 1.49001L3.4009 4.98281L3.09229 5.15426V5.5073V12.4929V12.8459L3.4009 13.0174L9.68794 16.5102L9.97933 16.6721L10.2707 16.5102L16.5578 13.0174L16.8664 12.8459V12.4929V5.5073V5.15426L16.5578 4.98281L10.2707 1.49001ZM4.29229 12.1399V5.86034L9.97933 2.70088L15.6664 5.86034V12.1399L9.97933 15.2993L4.29229 12.1399ZM11.5735 8.99983C11.5735 9.8803 10.8597 10.5941 9.97922 10.5941C9.09874 10.5941 8.38498 9.8803 8.38498 8.99983C8.38498 8.11935 9.09874 7.40559 9.97922 7.40559C10.8597 7.40559 11.5735 8.11935 11.5735 8.99983ZM12.7735 8.99983C12.7735 10.543 11.5224 11.7941 9.97922 11.7941C8.436 11.7941 7.18498 10.543 7.18498 8.99983C7.18498 7.45661 8.436 6.20559 9.97922 6.20559C11.5224 6.20559 12.7735 7.45661 12.7735 8.99983Z"
				fill="#797A80"
			/>
		</svg>
	);
}
