import React, { FC } from 'react';
import { Menu, MenuItem } from "@mui/material";

export enum ElemInPopover {
	orderStatus,
	packageStatus,
	evaluationStatus
}

interface IPopoverComponent {
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	elemInPopover: (ElemInPopover | null)[],
	onClose: () => void;
	onClick: (value: "order_status" | "package_status" | "evaluation_status") => void,

}

export const PopoverComponent: FC<IPopoverComponent> = ({ open, anchorEl, onClose, onClick, elemInPopover }) => {
	return (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}>
			<>
				{elemInPopover.some(e => e === ElemInPopover.orderStatus)
					? <MenuItem onClick={() => onClick("order_status")}>Требует подтверждения</MenuItem>
					: null}
				{elemInPopover.some(e => e === ElemInPopover.packageStatus)
					? <MenuItem onClick={() => onClick("package_status")}>Требует упаковки</MenuItem>
					: null}
				{elemInPopover.some(e => e === ElemInPopover.evaluationStatus)
					? <MenuItem onClick={() => onClick("evaluation_status")}>Требует оценки</MenuItem>
					: null}
			</>
		</Menu>
	);
}
