import React, { FC } from 'react';
import { Button } from '@mui/material';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import { logoutUser } from '../../service/AuthService';
import styles from './logoutButton.module.css';
import { API_PREFIX } from '../../utils/const';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/redux';
import ButtonLogout from '../Buttons/button/ButtonLogout';

const LogoutButton: FC = () => {
	const dispatch = useDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const logout = async () => {
		const response = await logoutUser();
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при завершение сеанса',
				})
			);
		} else {
			window.location.replace(`${API_PREFIX}/auth/login`)
		}
	};
	return (
		<div>
			<Button className={styles.logoutButton}>
				<p>{profile?.display_name ?? ''}</p>
			</Button>
			<Button onClick={logout}>
				<ButtonLogout>Выход</ButtonLogout>
			</Button>
		</div>
	);
};

export default LogoutButton;
