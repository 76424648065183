import { createSlice } from '@reduxjs/toolkit';

type ModalState = {
	balanceModal: { show: boolean },
	settingsModal: { show: boolean },
	settingsProfileModal: { show: boolean },
	bitrixModal: { show: boolean }
};

const initialState: ModalState = {
	balanceModal: {
		show: false,
	},
	settingsModal: {
		show: false,
	},
	settingsProfileModal: {
		show: false,
	},
	bitrixModal: {
		show: false,
	},
};

export const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		setBalanceModal: (state, data) => {
			state.balanceModal = data.payload;
		},
		setSettingsModal: (state, data) => {
			state.settingsModal = data.payload;
		},
		setSettingsProfileModal: (state, data) => {
			state.settingsProfileModal = data.payload;
		},
		setBitrixModal: (state, data) => {
			state.bitrixModal = data.payload;
		},
	},
});

export const { setBalanceModal, setSettingsModal, setSettingsProfileModal, setBitrixModal } =
	modalsSlice.actions;

export default modalsSlice.reducer;
