import React, { FC } from 'react';
import styles from './BalanceButton.module.css';

type BalanceButtonProps = {
	onClick: () => void,
	text: string,
	arrow: JSX.Element,
	icon: JSX.Element,
	balance: number,
};

const BalanceButton: FC<BalanceButtonProps> = ({
	text,
	arrow,
	icon,
	balance,
	onClick,
}) => {
	return (
		<button className={styles.balance_button} onClick={onClick}>
			<div className={styles.align}>
				{icon}
				<span>{balance}</span>
				{text}
				<p>{arrow}</p>
			</div>
		</button>
	);
};

export default BalanceButton;
