import resolve from './resolve';
import axios from 'axios';
import { IOrders } from '../models/IOrders';
import { API_PREFIX } from '../utils/const';

// import mockData from './__mock__/Orders.json';

interface returnOrdersI {
	total: number;
	data: IOrders[];
	success: boolean;
}
interface searchI {
	[key: string]: string;
}

export async function deleteOrders(_id: string): Promise<void> {
	return await resolve(
		axios.patch(`${API_PREFIX}/orders/${_id}`, {
			withCredentials: true,
		})
	);
}

export async function changeOrders(
	_id: string,
	value: 'order_status' | 'package_status' | 'evaluation_status'
): Promise<void> {
	const body =
		value === 'order_status'
			? { order_status: 'Остановлен' }
			: value === 'evaluation_status'
			? { evaluation_status: 'Остановлен' }
			: { package_status: 'Остановлен' };
	return await resolve(
		axios.patch(`${API_PREFIX}/orders/${_id}`, body, { withCredentials: true })
	);
}

export async function returnOrders(
	page: number,
	searchOption: string,
	skip: number,
	limit: number,
	sort: {
		sortField: any,
	},
	order: {
		sortOrder: any,
	},
	// clientID: string,
	searchStart: string,
	searchEnd: string,
	search: searchI
): Promise<any> {
	const reqSearch = () => {
		// const normalizedGdate = (date: string): string => {
		// 	return date.replace(/-/g, '.');
		// };
		const searchObj = Object.assign({}, search, {
			date: {
				gte: new Date(searchStart).getTime(),
				lte: new Date(searchEnd).getTime(),
			},
		});

		// Object.keys(search).forEach((key) => {
		// 	if (search[key].length > 0) {
		// 		searchObj[key] = search[key];
		// 	}
		// });
		return JSON.stringify(searchObj);
	};
	// console.log(reqSearch, page, searchOption, start, sizePerPage, sort)

	// return mockData;
	return await resolve(
		axios.get<returnOrdersI>(`${API_PREFIX}/orders`, {
			withCredentials: true,
			params: {
				page: page,
				search: reqSearch(),
				searchOption: searchOption,
				skip: skip,
				limit: limit,
				sort: sort.sortField,
				order: order.sortOrder,
			},
		})
	);
}
