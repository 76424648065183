import { createSlice } from '@reduxjs/toolkit';

const newStartDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 00:00:00`;

const newEndDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 23:59:59`;

const initialState = {
	isLoading: false,
	stats: [],
	sort: {
		sortOrder: 'desc',
		sortField: 'date',
	},
	page: 1,
	start: 0,
	searchStart: newStartDate,
	searchEnd: newEndDate,
	searchOption: 'date',
	search: {},
	totalSize: 0,
	filter: {},
	sizePerPage: 10,
};

export const statsSlice = createSlice({
	name: 'stats',
	initialState,
	reducers: {
		setLoading: (state, data) => {
			state.isLoading = data.payload;
		},
		setStats: (state, data) => {
			state.stats = data.payload;
		},
		setTotalSizeStats: (state, data) => {
			state.totalSize = data.payload;
		},
		setPageStats: (state, data) => {
			state.page = data.payload;
		},
		setStart: (state, data) => {
			state.start = data.payload;
		},
		setSort: (state, sort) => {
			state.sort = sort.payload;
		},
		setFilter: (state, data) => {
			state.filter = data.payload;
		},
		setSearch: (state, data) => {
			// state.search.push({data.payload.type:data.payload.value});
			Object.assign(state.search, data.payload);
		},
		setSearchOption: (state, data) => {
			state.searchOption = data.payload;
		},
		setSizePerPageStats: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSearchStartStats: (state, searchStart) => {
			state.searchStart = searchStart.payload;
		},
		setSearchEndStats: (state, searchEnd) => {
			state.searchEnd = searchEnd.payload;
		},
	},
});

export default statsSlice.reducer;
export const {
	setLoading,
	setStats,
	setTotalSizeStats,
	setPageStats,
	setSizePerPageStats,
	setSort,
	setStart,
	setSearchStartStats,
	setSearchEndStats,
	setSearch,
} = statsSlice.actions;
