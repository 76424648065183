import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Navigate, useLocation } from 'react-router-dom';
import { API_PREFIX } from '../../utils/const';

const LoginForm: FC = () => {
	const { isAuthenticated } = useAppSelector((state) => state.profileReducer);
	const location = useLocation();

	return (
		<>
			{!isAuthenticated ? (
				window.location.replace(`${API_PREFIX}/auth/login`)
			) : (
				<Navigate to="/" state={{ from: location }} />
			)}
		</>
	);
};
export default LoginForm;
