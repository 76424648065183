import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogContentText, DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styles from './SettingsForm.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { setTestTask, updateProfile } from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import TestForm from '../TestForm/TestForm';
import ApiForm from '../ApiForm/ApiForm';
import PlayTextButton from '../PlayTextButton/PlayTextButton';
import { BITRIX_INTEGRETION, NEGATIVE_FEEDBACK, POSITIVE_FEEDBACK, TASKS_TEXT } from '../../utils/const';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import { setBalanceModal } from '../../store/reducers/ModalSlice';
import ReactDOM from 'react-dom';

type SettingsFormsProps = {
	open: boolean,
	setClose: (value: boolean) => void,
};

const SettingsForm: FC<SettingsFormsProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const recordValue: boolean =
		typeof (profile?.recording_shop) === 'boolean' ? profile?.recording_shop : false;

	const voiceValue: string =
		typeof (profile?.voice) === 'string' ? profile?.voice : 'oksana';

	const initBitrixIntegration = () => {
		if (
			profile?.bitrix_integration_shop === true ||
			profile?.bitrix_integration_shop === false
		)
			return profile?.bitrix_integration_shop;
		return BITRIX_INTEGRETION;
	};

	const initTasksText = () => {
		if (profile?.tasks_text) return profile?.tasks_text;
		return TASKS_TEXT;
	};
	const initPositiveFeedback = () => {
		if (profile?.positive_feedback) return profile?.positive_feedback;
		return POSITIVE_FEEDBACK;
	};
	const initNegativeFeedback = () => {
		if (profile?.negative_feedback) return profile?.negative_feedback;
		return NEGATIVE_FEEDBACK;
	}
	const retryCallValue: string =
		typeof (profile?.retry_call_shop) === 'number' ? `${profile?.retry_call_shop}` : '0';

	const playListValue: string =
		typeof (profile?.play_list_items) === 'boolean' ? `${profile?.play_list_items}` : 'false';

	const playSendValue: string =
		typeof (profile?.play_send_email) === 'boolean' ? `${profile?.play_send_email}` : 'false';

	const playLikeValue: string =
		typeof (profile?.play_like_text) === 'boolean' ? `${profile?.play_like_text}` : 'false';

	const [name, setName] = useState<string>(profile?.name || "");
	const [tasksText, setTasksText] = useState<string>(initTasksText);
	const [positiveFeedback, setPositiveFeedback] = useState<string>(initPositiveFeedback);
	const [negativeFeedback, setNegativeFeedback] = useState<string>(initNegativeFeedback);
	const [url, setUrl] = useState<string>(profile?.url || "");
	const [token, setToken] = useState<string>(profile?.token_access || "");
	const [retryCall, setRetryCall] = useState<string>(retryCallValue);
	const [listItems, setListItems] = useState<string>(playListValue);
	const [email, setEmail] = useState<string>(playSendValue);
	const [likeText, setLikeText] = useState<string>(playLikeValue);
	const [record, setRecord] = useState<boolean>(recordValue);
	const [number, setNumber] = useState<string>('');
	const [orders, setOrders] = useState<string>('');
	const [testEmail, setTestEmail] = useState<string>('');
	const [phone, setPhone] = useState<string>('');
	const [voice, setVoice] = useState<string>(voiceValue);
	const [disabledPlayButton, setPlayButton] = useState<boolean>(true);
	const [bitrixIntegration, setBitrixIntegration] = useState<boolean>(
		initBitrixIntegration
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (profile) {
			setVoice(profile.voice ?? 'oksana');
		}
	}, [profile]);

	const handleCloseSetings = () => {
		setNumber('');
		setOrders('');
		setTestEmail('');
		setPhone('');
		setBitrixIntegration(initBitrixIntegration);
		setClose(false)
		// setVoice('');
	};

	useEffect(() => {
		setListItems(playListValue);
		setEmail(playSendValue);
		setLikeText(playLikeValue);
		setRecord(recordValue);
		setName(profile?.name ?? '');
		setUrl(profile?.url ?? '');
		setToken(profile?.token_access ?? '');
		setRetryCall(retryCallValue);
		setBitrixIntegration(initBitrixIntegration);
		setTasksText(initTasksText);
		setPositiveFeedback(initPositiveFeedback);
		setNegativeFeedback(initNegativeFeedback);
		// setOpenSet(true);
	}, [profile, open]);

	useEffect(() => {
		number.length > 0 &&
			orders.length > 0 &&
			testEmail.length > 0 &&
			phone.length > 0 &&
			name.length > 0
			? setPlayButton(false)
			: setPlayButton(true);
	}, [number, orders, testEmail, phone, voice, name]);

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'name') {
			setName(evt.target.value);
		} else if (type === 'url') {
			setUrl(evt.target.value);
		} else if (type === 'token') {
			setToken(evt.target.value);
		} else if (type === 'tasksText') {
			setTasksText(evt.target.value);
		} else if (type === 'positiveFeedback') {
			setPositiveFeedback(evt.target.value);
		} else if (type === 'negativeFeedback') {
			setNegativeFeedback(evt.target.value);
		} else if (type === 'retryCall') {
			if ((/^\d+$/.test(evt.target.value) && Number(evt.target.value) <= 4) || evt.target.value === '') {
				console.log(evt.target.value)
				setRetryCall(evt.target.value);
			}
		}
	};

	const updateTestData = (key: string, value: string) => {
		if (key === 'number') {
			setNumber(value);
		} else if (key === 'orders') {
			setOrders(value);
		} else if (key === 'testEmail') {
			setTestEmail(value);
		} else if (key === 'phone') {
			setPhone(value);
		} else if (key === 'voice') {
			setVoice(value);
		}
	};

	const changeHandler = (evt: any, type: string) => {
		// console.log(evt.target.checked);
		const value = String(evt.target.checked);
		if (type === 'listItems') {
			setListItems(value);
		} else if (type === 'email') {
			setEmail(value);
		} else if (type === 'likeText') {
			setLikeText(value);
		} else if (type === 'record') {
			setRecord(evt.target.checked);
		} else if (type === 'bitrix') {
			setBitrixIntegration(evt.target.checked);
		}
	};

	const handleUpdateProfile = async () => {
		if (
			name.length === 0 || (!name.trim()) ||
			url.length === 0 || (!url.trim()) ||
			token.length === 0 || (!token.trim()) ||
			retryCall.length === 0 ||
			tasksText.length === 0 || (!tasksText.trim()) ||
			positiveFeedback.length === 0 || (!positiveFeedback.trim()) ||
			negativeFeedback.length === 0 || (!negativeFeedback.trim())
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}
		const response = await updateProfile(
			name,
			listItems,
			email,
			record,
			likeText,
			url,
			profile!.first_text,
			profile!.second_text,
			profile!.third_text,
			profile!.fourth_text,
			profile!.confirm_text,
			profile!.like_text,
			profile!.last_text,
			retryCall,
			token,
			bitrixIntegration,
			tasksText,
			negativeFeedback,
			positiveFeedback,
			voice
		);
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			handleCloseSetings();
		}
	};

	const returnTextScript = () => {
		const recordText = 'Внимание, Ваш разговор записывается';
		const helloText = `Здравствуйте это интернет-магазин ${name}.`;
		const numberText = `Хотим подтвердить ваш заказ ${number}`;
		const ordersText = orders;
		const emailText = `Счет на оплату отправлен на ${testEmail}`;
		const requestText = `Для подтверждения заказа скажите д+а для отмены заказа скажите нет.`;
		const confirmText = `Спасибо, ваш заказ подтвержден!`;
		const likeMessage = `Если вам понравилось качество обслуживания, скажите д+а, если нет - оставьте сообщение`;
		return `${record ? recordText : ''}. ${helloText}. ${numberText}. ${listItems === 'true' ? '' : ordersText
			}. ${email === 'true' ? '' : emailText}.
		${requestText}. ${confirmText}. ${likeText === 'true' ? '' : likeMessage}`;
	};

	// const passwordValue: string = Object.prototype.hasOwnProperty.call(
	// 	profile,
	// 	'encryptedPassword_shop'
	// )
	// 	? profile!.encryptedPassword_shop!
	// 	: profile!.encryptedPassword;

	const handleTestSip = async () => {
		if (
			profile!.phone_shop === undefined ||
			profile!.phone_shop.length <= 0 ||
			profile!.phone_pass_shop === undefined ||
			profile!.phone_pass_shop.length <= 0 ||
			profile!.server_sip_shop === undefined ||
			profile!.server_sip_shop.length <= 0
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо настроить сип-аккаунт',
				})
			);
		}
		const responseUpdateProfile = await updateProfile(
			name,
			listItems,
			email,
			record,
			likeText,
			url,
			profile!.first_text,
			profile!.second_text,
			profile!.third_text,
			profile!.fourth_text,
			profile!.confirm_text,
			profile!.like_text,
			profile!.last_text,
			retryCall,
			'',
			// btoa(`${profile!.login}:${atob(passwordValue)}`),
			bitrixIntegration,
			tasksText,
			negativeFeedback,
			positiveFeedback,
			voice
		);
		if (responseUpdateProfile.error) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			const response = await setTestTask(
				number,
				orders,
				testEmail,
				phone,
				voice,
				// btoa(`${profile!.login}:${atob(passwordValue)}`)
				' '
			);
			if (profile?.balance_shop === 0) {
				dispatch(setBalanceModal({ show: true }));
				return
			}
			if (response.error) {
				const { msg } = response.error.response.data;
				const textError = msg || 'Произошла ошибка при запуске тестовой задачи';
				dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp: textError,
					})
				);
			} else {
				dispatch(
					setInfoApp({
						isInfoApp: true,
						infoApp:
							'Сценарий тестирования подтверждения заказов успешно активирован',
					})
				);
				dispatch(fetchProfile());
				handleCloseSetings();
			}
		}
	};

	return ReactDOM.createPortal(
		<Dialog scroll="body" open={open} onClose={handleCloseSetings}>
			<DialogTitle className={styles.profile_label}>
				Настройки сценария
				<button onClick={handleCloseSetings} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div>
				<div className={styles.container}>
					<div className={styles.wrapper_for_block}>
						<DialogContent className={styles.dialog_content}>
							<TextField
								margin="dense"
								label="Email"
								defaultValue={profile?.email}
								variant="standard"
								fullWidth
								className={styles.email_input}
							/>
							<TextField
								margin="dense"
								id="new_name"
								label="Наименование магазина"
								fullWidth
								variant="standard"
								defaultValue={profile?.name}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'name')
								}
								error={name.length <= 0 || (!name.trim())}
							/>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'bitrix')
									}
									checked={bitrixIntegration}
									label="Интеграция с 1С-Битрикс"
									labelPlacement="end"
								/>
							</FormGroup>
							{bitrixIntegration ? (
								<>
									<TextField
										margin="dense"
										id="new_url"
										label="URL обработчика статусов заказов"
										fullWidth
										variant="standard"
										defaultValue={profile?.url}
										onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
											handleChange(evt, 'url')
										}
										error={url.length <= 0 || (!url.trim())}
									/>
									<TextField
										margin="dense"
										id="token_access"
										label="Токен доступа"
										fullWidth
										variant="standard"
										defaultValue={profile?.token_access}
										onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
											handleChange(evt, 'token')
										}
										error={token.length <= 0 || (!token.trim())}
									/>
								</>
							) : null}
							<TextField
								inputProps={{ inputMode: 'numeric', min: 0, max: 4 }}
								margin="dense"
								id="retry_call_shop"
								label="Повторных звонков"
								fullWidth
								variant="standard"
								value={retryCall}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'retryCall')
								}
								error={retryCall.length <= 0 || (!retryCall.trim())}
							/>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'listItems')
									}
									checked={listItems === 'true'}
									label="Не воспроизводить перечень товаров"
									labelPlacement="end"
								/>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'email')
									}
									checked={email === 'true'}
									label="Не воспроизводить фразу об уведомлении на почту"
									labelPlacement="end"
								/>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'likeText')
									}
									checked={likeText === 'true'}
									label="Не воспроизводить оценку качества"
									labelPlacement="end"
								/>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'record')
									}
									checked={record}
									label="Включить запись (Внимание! При включении этой опции, в начало обзвона вставляется предупреждение клиента о записи звонка.)"
									labelPlacement="end"
								/>
							</FormGroup>
						</DialogContent>
					</div>
					<div className={styles.wrapper_for_block}>
						<div className={styles.wrapper_text}>
							<DialogContentText className={styles.label_for_context_text}>
								Сценарий для оценки качества обслуживания
							</DialogContentText>
							<TextField
								margin="dense"
								id="tasksText"
								label="Текст (используйте <@name@>, <@number@> для включения данных в текст)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={tasksText}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'tasksText')
								}
								error={tasksText.length <= 0 || (!tasksText.trim())}
							/>
							<TextField
								margin="dense"
								id="negativeFeedback"
								label="Оценка отрицательная (от 1 до 3)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={negativeFeedback}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'negativeFeedback')
								}
								error={negativeFeedback.length <= 0 || (!negativeFeedback.trim())}
							/>
							<TextField
								margin="dense"
								id="positiveFeedback"
								label="Оценка положительная (от 4 до 5)"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={positiveFeedback}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'positiveFeedback')
								}
								error={positiveFeedback.length <= 0 || (!positiveFeedback.trim())}
							/>
						</div>
					</div>
					<TestForm updateTestData={updateTestData} />
					<PlayTextButton
						voice={voice}
						text={returnTextScript().trim()}
						disabled={disabledPlayButton}
					/>
					<ApiForm
						number={number}
						orders={orders}
						email={testEmail}
						phone={phone}
						voice={voice}
					/>
					<DialogActions className={styles.dialogActions}>
						<Button
							disabled={
								number.length == 0 ||
								orders.length == 0 ||
								testEmail.length == 0 ||
								phone.length == 0 ||
								name.length === 0 ||
								url.length === 0 ||
								token.length === 0 ||
								!retryCall ||
								Number(retryCall) < 0 ||
								Number(retryCall) > 4
							}
							onClick={handleTestSip}
						>
							Протестировать
						</Button>
						<Button
							disabled={
								name === profile?.name &&
								url === profile?.url &&
								token === profile?.token_access &&
								retryCall === profile?.retry_call_shop &&
								email === profile?.play_send_email &&
								likeText === profile?.play_like_text &&
								listItems === profile?.play_list_items &&
								bitrixIntegration === initBitrixIntegration() &&
								record === recordValue
							}
							onClick={handleUpdateProfile}
						>
							Сохранить
						</Button>
					</DialogActions>
					<DialogActions>
						{/*<Button onClick={onActivateLicence}>Активировать ключ</Button>*/}
					</DialogActions>
				</div>
			</div>
		</Dialog >,
		document.getElementById('settingsForm')!
	);
};

export default SettingsForm;
