import React, { useEffect } from 'react';
import './App.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import LoginForm from '../LoginForm/LoginForm';
import Main from '../Main/Main';
import Preloader from '../Preloader/Preloader';
import MessageWrapper from '../MessageWrapper/MessageWrapper';
import { fetchAuth, fetchProfile } from '../../service/ProfileServiceThunk';

function App(): JSX.Element {
	const dispatch = useAppDispatch();
	const { isLoading, profile } = useAppSelector(
		(state) => state.profileReducer
	);

	useEffect(() => {
		dispatch(fetchAuth());
		dispatch(fetchProfile());
	}, []);

	function RequireAuth({ children }: { children: JSX.Element }) {
		const location = useLocation();
		if (!profile) {
			return <Navigate to="/login" state={{ from: location }} />;
		}
		return children;
	}

	return (
		<div className="App">
			{isLoading ? (
				<Preloader />
			) : (
				<Router basename="/">
					<Routes>
						<Route path="/login" element={<LoginForm />} />
						<Route
							path="/"
							element={
								<RequireAuth>
									<Main />
								</RequireAuth>
							}
						/>
					</Routes>
				</Router>
			)}
			<MessageWrapper />
		</div>
	);
}

export default App;
