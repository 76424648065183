import React, { FC, useEffect, useState } from 'react';
import {
	Input,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { columnsCalls } from './column';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import {
	setLoading,
	setStats,
	setTotalSizeStats,
	setPageStats,
	setSizePerPageStats,
	setSort,
	setStart,
	setSearchStartStats,
	setSearchEndStats,
	setSearch,
} from '../../store/reducers/StatsSlice';
import TablePagination from '@mui/material/TablePagination';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';
import './tableStats.css';
import { returnStats } from '../../service/StatsService';

const TableStats: FC = () => {
	const dispatch = useAppDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const {
		isLoading,
		stats,
		sort,
		page,
		start,
		searchOption,
		totalSize,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
	} = useAppSelector((state) => state.statsReducer);
	const [statusCol, setStatusCol] = useState<string>('all');

	useEffect(() => {
		(async () => {
			dispatch(setLoading(true));
			const response = await returnStats(
				page,
				searchOption,
				start,
				sizePerPage,
				sort,
				profile!.clientID,
				searchStart,
				searchEnd,
				search
			);
			if (response.error) {
				dispatch(setLoading(false));
				dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp: 'Произошла ошибка при получении статистики',
					})
				);
			} else {
				dispatch(setLoading(false));
				const { total, data } = response.data.data;
				dispatch(setStats(data));
				dispatch(setTotalSizeStats(total));
			}
		})();
	}, [
		page,
		sort,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
		dispatch,
		profile,
		searchOption,
		start,
	]);

	const handleChangePage = (event: any, newPage: any) => {
		dispatch(setPageStats(newPage + 1));
		dispatch(setStart(newPage * sizePerPage));
	};

	const handleChangeRowsPerPage = (e: any) => {
		dispatch(setSizePerPageStats(e.target.value));
		dispatch(setPageStats(1));
		dispatch(setStart(1));
	};

	const createSortHandler = (e: any, field: string) => {
		console.log(e.target.tagName);
		if (
			e.target.tagName === 'svg' ||
			e.target.tagName === 'SPAN' ||
			e.target.tagName === 'path'
		) {
			const isAsc = sort.sortField === field && sort.sortOrder === 'asc';
			dispatch(setPageStats(1));
			dispatch(
				setSort({
					sortOrder: isAsc ? 'desc' : 'asc',
					sortField: field,
				})
			);
		}
	};

	const changeTime = (time: any) => {
		const transformTime = (time: Date) => {
			return `${time.getFullYear()}-${
				time.getMonth() + 1 < 10
					? `0${time.getMonth() + 1}`
					: time.getMonth() + 1
			}-${time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()} ${
				time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
			}:${
				time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
			}`;
		};
		const normalizeTime = (): string => {
			const startTimeNorm = transformTime(time[0]).split(' ');
			const endTimeNorm = transformTime(time[1]).split(' ');
			if (startTimeNorm[1] === '00:00' && endTimeNorm[1] === '00:00') {
				endTimeNorm[1] = '23:59';
			}
			return endTimeNorm.join(' ');
		};
		const endTime = normalizeTime();
		dispatch(setSearchStartStats(transformTime(time[0])));
		dispatch(setSearchEndStats(endTime));
	};

	// @ts-ignore
	const customLabel = ({ from, to, count }) => {
		return `${from}-${to} из ${count}`;
	};

	const handleChangeInput = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		console.log(evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value;
		dispatch(setSearch(obj));
	};

	const handleSelect = (evt: SelectChangeEvent<any>, type: string) => {
		const obj: any = {};
		obj[type] = evt.target.value === 'all' ? '' : evt.target.value;
		if (type === 'operations') {
			setStatusCol(evt.target.value);
			dispatch(setSearch(obj));
		}
	};

	const setValue = (type: string) => {
		if (type === 'operations') {
			return statusCol;
		}
	};

	return (
		<div className="container">
			<p className="label">Детализация</p>
			<div className="wrapper-for_datarange">
				<CustomProvider locale={ruRU}>
					<DateRangePicker
						cleanable={false}
						// ranges={[]}
						onChange={changeTime}
						hoverRange={(date) => [date, date]}
						value={[
							new Date(searchStart.replace(/-/g, '/')),
							new Date(searchEnd.replace(/-/g, '/')),
						]}
						format="yyyy-MM-dd HH:mm"
					/>
				</CustomProvider>
			</div>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer className="tableContainer">
					<Table className="table" stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow className="row_header">
								{columnsCalls.map((column) => (
									<TableCell
										align="center"
										key={column.field}
										padding="normal"
										style={{
											width: column.width,
										}}
										// @ts-ignore
										sortDirection={
											sort.sortField === column.field ? sort.sortOrder : 'asc'
										}
									>
										{column.headerName}
										{column.sort ? (
											<TableSortLabel
												key={column.field}
												active={sort.sortField === column.field}
												// @ts-ignore
												direction={
													sort.sortField === column.field
														? sort.sortOrder
														: 'asc'
												}
												onClick={(e) => createSortHandler(e, column.field)}
											/>
										) : null}
										{column.input ? (
											<Input
												key={`${column.field}_input`}
												className={column.input ? 'tableCell_input' : ''}
												// id="component-helper"
												onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
													handleChangeInput(evt, `${column.field}`)
												}
												aria-describedby="component-helper-text"
											/>
										) : null}
										{column.select ? (
											<Select
												key={`${column.field}_select`}
												className={column.select ? 'tableCell_select' : ''}
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={setValue(`${column.field}`)}
												label={`${column.field}`}
												onChange={(evt: SelectChangeEvent<any>) =>
													handleSelect(evt, `${column.field}`)
												}
											>
												{column.menuItem.map((item) => {
													return (
														<MenuItem key={item.value} value={item.value}>
															{item.label}
														</MenuItem>
													);
												})}
											</Select>
										) : null}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{stats.length > 0 ? (
								stats.map((row:any, index) => {
									return (
										<TableRow
											className={index % 2 ? 'row_background' : ''}
											key={row['_id']}
										>
											<TableCell align="center">{row?.date}</TableCell>
											<TableCell align="center">{row?.service}</TableCell>
											<TableCell align="center">{row?.operations}</TableCell>
											<TableCell align="center">{`${row?.value}`}</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow/>
							)}
						</TableBody>
					</Table>
					{stats.length > 0 ? null : (
						<p className="label-no_records">Нет записей</p>
					)}
				</TableContainer>
			</Paper>
			{isLoading ? <LinearProgress /> : null}
			<div className="wrapper-footer_stats row_background">
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100, 500]}
					component="div"
					count={totalSize}
					rowsPerPage={sizePerPage}
					page={page - 1}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="На странице:"
					labelDisplayedRows={customLabel}
				/>
			</div>
		</div>
	);
};

export default TableStats;
