import React, { FC } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

interface AlertMessageProps {
	open: boolean;
	close: any;
	severity: AlertColor;
	message: string;
}

const AlertMessage: FC<AlertMessageProps> = ({
	open,
	close,
	severity,
	message,
}) => {
	return (
		<Snackbar
			open={open}
			onClose={close}
			autoHideDuration={4000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		>
			<Alert
				severity={severity}
				variant="filled"
				sx={{ width: '100%' }}
				onClose={close}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default AlertMessage;
