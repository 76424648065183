import React, { FC, ReactNode } from 'react';
import './style.css';

type CencelHeaderButtonProps = {
	onClick: () => void,
	text: string,
	disabled?: boolean,
	className?: ReactNode,
};
const CencelHeaderButton: FC<CencelHeaderButtonProps> = ({
	onClick,
	text,
	disabled,
}) => {
	return (
		<div
			className={disabled ? 'close_balance disabled_button' : 'close_balance'}
			onClick={onClick}
		>
			{text}
		</div>
	);
};

export default CencelHeaderButton;
