import { URL_AUTH } from '../utils/const';

export default async function resolve(promise: Promise<any>): Promise<any> {
	const resolved = {
		data: null,
		error: null,
	};

	try {
		resolved.data = await promise;
	} catch (e: any) {
		// if (e.response.status === 401) {
		// 	window.location.replace(`${URL_AUTH}`);
		// }
		resolved.error = e;
	}
	return resolved;
}
