import React, { FC } from 'react';
import styles from './header.module.css';
import Profile from '../Icons/Profile/Profile';
import ShopIcon from '../Icons/Shop/ShopIcon';
import BalanceButton from '../Buttons/balanceButton/BalanceButton';
import { useAppSelector } from '../../hooks/redux';
import Wallet from '../Icons/Wallet/Wallet';
import ArrowBottomIcon from '../Icons/ArrowBottom/ArrowBottomIcon';
import { useDispatch } from 'react-redux';
import {
	setBalanceModal,
	setSettingsModal,
	setSettingsProfileModal,
} from '../../store/reducers/ModalSlice';
import NavMenuButton from '../Buttons/navMenuButton/NavMenuButton';
import Log from '../Icons/Log/Log';
import { setView } from '../../store/reducers/ViewSlice';
import { setDataPage } from '../../store/reducers/PagesSlice';
import Play from '../Icons/Play/Play';
import Settings from '../Icons/Settings/Settings';
import Counter from '../Icons/Counter/Counter';
import LogoutButton from '../LogoutButton/LogoutButton';

const Header: FC = () => {
	const dispatch = useDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const page = useAppSelector((state) => state.pagesReducer);

	const onSetViewCalls = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('calls'));
		}
	};

	const onSetViewVideos = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('video'));
		}
	};

	const onSetViewOrders = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('orders'));
		}
	};

	const onSetViewEvaluation = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('evaluations'));
		}
	};

	const showSettingsModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showSettingsProfileModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsProfileModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showModal = () => {
		dispatch(
			setBalanceModal({
				show: true,
			})
		);
	};

	return (
		<header className={styles.header}>
			<div className={styles.header__centralized_container}>
				<div className={styles.header__left_side}>
					<div className={styles.header__logo}>
						<ShopIcon />
						<span>подтверждение заказа</span>
						<p>|</p>
						<p>Kloud.one</p>
					</div>
				</div>
				<div className={styles.header__right_side}>
					<Profile />
					<LogoutButton />
				</div>
			</div>
			<div className={styles.navbar}>
				<div className={styles.navbar_header}>
					<div className={styles.navbar_header__container}>
						<NavMenuButton
							text="Заказы"
							page={page.data}
							onClick={onSetViewOrders}
							icon={<Counter />}
						/>
						<NavMenuButton
							text="Журнал звонков"
							page={page.data}
							onClick={onSetViewCalls}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Журнал видео"
							page={page.data}
							onClick={onSetViewVideos}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Журнал оценки"
							page={page.data}
							onClick={onSetViewEvaluation}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Настройки подключения"
							page={page.data}
							onClick={showSettingsProfileModal}
							icon={<Settings />}
						/>
						<NavMenuButton
							text="Настройки сценария"
							page={page.data}
							onClick={showSettingsModal}
							icon={<Play />}
						/>
					</div>
					<div className={styles.navbar_header__balance}>
						<BalanceButton
							balance={profile?.balance_shop ?? 0}
							text="звонков"
							onClick={showModal}
							icon={<Wallet />}
							arrow={<ArrowBottomIcon />}
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
