export const columnsCalls = [
	{
		headerName: 'Дата',
		field: 'date',
		width: 350,
		sort: false,
	},
	/*{
        headerName: 'Сценарий',
        field: 'script',
        width: 250,
    },
    {
        headerName: 'Тип',
        field: 'type',
        width: 350,
    },*/
	{
		headerName: 'Приложение',
		field: 'service',
		width: 250,
	},
	{
		headerName: 'Тип операции',
		field: 'operations',
		select: true,
		selectValue: 'all',
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'add', label: 'Пополнение' },
			{ value: 'delete', label: 'Списание' },
		],
		width: 250,
	},
	{
		headerName: 'Значение',
		field: 'value',
		width: 250,
		input: false,
		sort: true,
	},
	/*{
		headerName: 'Длительность',
		field: 'duration',
		width: 250,
	},
	{
		headerName: 'Файл',
		field: 'media',
		width: 250,
	},
	{
		headerName: 'Результат',
		field: 'step',
		width: 250,
		select: true,
		selectValue: 'all',
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'успешно', label: 'успешно' },
			{ value: 'не успешно', label: 'не успешно' },
		],
	},*/
];
