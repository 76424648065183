import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import ModalCustom from '../ModalCustom/ModalCustom';
import { setWarningBalance } from '../../service/UsersService';

const MessageBalance: FC = () => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (profile!.balance_shop <= 0 && !profile?.warning_shop) setOpen(true);
	}, [profile]);

	const handleCloseModal = async () => {
		const response = await setWarningBalance(true);
		if (response) {
			setOpen(false);
		}
	};

	return (
		<ModalCustom
			open={open}
			handleClose={handleCloseModal}
			text={'Необходимо пополнить баланс приложения'}
		/>
	);
};

export default MessageBalance;
